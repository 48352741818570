var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-event-detail"},[_c('Navbar'),_c('section',{staticClass:"share-event"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center text-center pt-5"},[_vm._m(0),_c('div',{staticClass:"col-md-5 mt-3"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-2 col-md-2 mx-3"},[_c('a',{staticClass:"text-white",attrs:{"href":("https://twitter.com/intent/tweet?text=" + (_vm.event.share) + " " + (_vm.currentURL())),"target":"_blank"}},[_c('i',{staticClass:"fa fa-twitter"})])]),_c('div',{staticClass:"col-2 col-md-2 mx-3"},[_c('a',{staticClass:"text-white",attrs:{"href":("https://www.facebook.com/sharer/sharer.php?u=" + (_vm.currentURL()) + " " + (_vm.event.share) + "&src=sdkpreparse"),"target":"_blank"}},[_c('i',{staticClass:"fa fa-facebook-f"})])]),_c('div',{staticClass:"col-2 col-md-2 mx-3"},[_c('a',{staticClass:"text-white",attrs:{"href":("https://api.whatsapp.com/send?text=" + (_vm.event.share) + " " + (_vm.currentURL())),"target":"_blank"}},[_c('i',{staticClass:"fa fa-whatsapp"})])])])])])])]),(Object.keys(_vm.event).length > 0)?_c('section',{staticClass:"event-wrap"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-12 col-lg-9 bg-white p-4 event-container"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              ("https://dashboard.infoin.auroraweb.id/storage/" + (_vm.event.thumbnail))
            ),expression:"\n              `https://dashboard.infoin.auroraweb.id/storage/${event.thumbnail}`\n            "}],staticClass:"img-fluid",attrs:{"alt":"event"}}),_c('div',{staticClass:"event-content my-3"},[_c('h1',{staticStyle:{"font-family":"poppins","font-weight":"500","color":"#393939"}},[_vm._v(" "+_vm._s(_vm.event.name)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.event.desc)+" ")])]),_c('div',{staticClass:"event-detail my-3"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-12"},[_c('ul',[_c('li',[_c('h5',{staticStyle:{"font-family":"poppins","font-weight":"500"}},[_vm._v(" Tanggal dan Waktu ")]),_c('p',[_vm._v(" "+_vm._s(_vm.setDate(_vm.event.date_start, "LLL"))+" - "+_vm._s(_vm.setDate(_vm.event.date_end, "LLL"))+" ")])]),_c('li',[_c('h5',{staticStyle:{"font-family":"poppins","font-weight":"500"}},[_vm._v(" Provinsi ")]),_c('p',[_vm._v(_vm._s(_vm.event.province.name))])]),_c('li',[_c('h5',{staticStyle:{"font-family":"poppins","font-weight":"500"}},[_vm._v(" Kota ")]),_c('p',[_vm._v(_vm._s(_vm.event.regency.name))])]),_c('li',[_c('h5',{staticStyle:{"font-family":"poppins","font-weight":"500"}},[_vm._v(" Event Organizer ")]),_c('router-link',{staticStyle:{"color":"#565a60"},attrs:{"to":{
                        name: 'Profile',
                        params: { slug: _vm.event.user.slug },
                      }}},[_c('p',[_vm._v(" "+_vm._s(_vm.event.user.name)+" ")])])],1),_c('li',{staticClass:"event-title"},[_c('h5',{staticStyle:{"font-family":"poppins","font-weight":"500"}},[_vm._v(" Status ")]),(_vm.event.status == 'Belum Dimulai')?_c('p',{staticClass:"badge badge-pill py-2 px-4 belum-dimulai status"},[_vm._v(" "+_vm._s(_vm.event.status)+" ")]):(_vm.event.status == 'Dimulai')?_c('p',{staticClass:"badge badge-pill py-2 px-4 dimulai status"},[_vm._v(" "+_vm._s(_vm.event.status)+" ")]):_c('p',{staticClass:"badge badge-pill py-2 px-4 selesai status"},[_vm._v(" "+_vm._s(_vm.event.status)+" ")])])])])])]),_c('div',{staticClass:"event-map my-3"},[_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-12"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                    ("https://dashboard.infoin.auroraweb.id/storage/" + (_vm.event.maps))
                  ),expression:"\n                    `https://dashboard.infoin.auroraweb.id/storage/${event.maps}`\n                  "}],staticClass:"w-100",attrs:{"alt":"map"}})])])])])])])]):_c('section',{staticClass:"event-wrap"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-12 col-lg-9 bg-white p-4 event-container"},[_c('b-skeleton-img'),_c('div',{staticClass:"event-content my-3"},[_c('b-skeleton',{attrs:{"animation":"wave","width":"85%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"55%"}}),_c('b-skeleton',{staticClass:"mt-4",attrs:{"animation":"wave","width":"100%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"70%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"55%"}})],1)],1)])])]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12"},[_c('h1',{staticClass:"text-white",staticStyle:{"font-weight":"600"}},[_vm._v(" Bagikan ke temanmu ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 mb-2"},[_c('span',{staticClass:"rounded-pill bg-danger"}),_c('h3',{staticStyle:{"font-family":"poppins","font-weight":"500","color":"#393939"}},[_vm._v(" Details Event ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 mb-2"},[_c('h3',{staticStyle:{"font-family":"poppins","font-weight":"500","color":"#393939"}},[_vm._v(" Peta ")])])}]

export { render, staticRenderFns }